import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { useNavigate } from "react-router-dom";

export type DashBoardTable = {
  topHeader: string[];
  header: string[];
  data: any;
};

export function ProgressDetailReport({
  data,
  header,
  topHeader,
}: DashBoardTable) {
  const styles = getStyles(defaultStyles);

  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead {...styles("header")}>
          <TableRow>
            {topHeader.map((item, index) => (
              <TableCell
                align="center"
                {...styles("cell")}
                key={index}
                colSpan={index ? 2 : 3}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {header.map((item, index) => (
              <TableCell align="center" {...styles("cell")} key={index}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow>
                <TableCell align="center" {...styles("cell")}>
                  {row.sno}
                </TableCell>

                <TableCell align="center" {...styles("cell")}>
                  <Typography
                    variant="body2"
                    color="primary"
                    onClick={() => {
                      if (header.includes("Division")) {
                        navigate("/division-progress-reports", {
                          state: {
                            stateId: row.redirectionKeyValue,
                            indicatorId: row.indicatorId,
                          },
                        });
                      } else {
                        navigate("/progress-details-report", {
                          state: {
                            stateId: row.redirectionKeyValue,
                            indicatorId: row.indicatorId,
                          },
                        });
                      }
                    }}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    {row.state}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  {...styles("cell", { width: "180px" })}
                >
                  {row.indicatorName}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.targetInUnits}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.costTarget}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.progressInUnits}
                </TableCell>
                <TableCell align="center" {...styles("cell")}>
                  {row.costProgress}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
