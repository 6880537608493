import UserDetails from "src/components/feature/UserDetails";
import Home from "src/pages";
import Admin from "src/pages/Admin";
import AdminLogin from "src/pages/admin-login";
import DemandDraftView from "src/pages/demand-draft";
import ForgetPassword from "src/pages/forget-password";
import ImplementingAgency from "src/pages/implementing-agency";
import LandOwnershipDetails from "src/pages/landOwnershipDetails";
import Login from "src/pages/login";
import MyProfile from "src/pages/my-profile";
import NotFound from "src/pages/not-found";
import ProjectDetailView from "src/pages/project-detail-view";
import RegisteredLands from "src/pages/registered-lands";
import Users from "src/pages/users";
import DemandNoteView from "src/pages/view-demand-draft";
import { path } from "./path";
import Marketwatch from "src/pages/market-watch";
import ChangePassword from "src/pages/change-password";
import DefaultFundApplication from "src/pages/fund-transfer";
import FundApplicationDetailView from "src/pages/fund-application-details";
import CostEstimateDetails from "src/pages/costEstimateDetailView";
import ProgressDetails from "src/pages/progress-details";
import UpdateProgressDetails from "src/pages/update-progress-details";
import AdvanceWorkProgress from "src/pages/advance-work-progress";
import ProgressDetailsReport from "src/pages/progressDetailsReport";
import DivisionProgressDetailsReport from "src/components/feature/progressProjectReport";

type RouteCOnfig = {
  path?: string;
  element: () => JSX.Element;
  isPublic?: boolean;
  index?: boolean;
};

export type RouteType<T extends string = string> = Record<
  keyof typeof path,
  RouteCOnfig
>;

const createRoute = <T extends string>(data: RouteType<T>) => data;

export const routes = createRoute({
  home: {
    path: path.home,
    element: Home,
  },
  userProfile: {
    path: path.userProfile,
    element: Admin,
  },
  notFound: {
    path: path.notFound,
    element: NotFound,
  },
  signIn: {
    path: path.signIn,
    element: AdminLogin,
  },
  login: {
    path: path.login,
    element: Login,
  },
  forgetPassword: {
    path: path.forgetPassword,
    element: ForgetPassword,
  },
  myProfile: {
    path: path.myProfile,
    element: MyProfile,
  },
  users: {
    path: path.users,
    element: Users,
  },
  implementingAgency: {
    path: path.implementingAgency,
    element: ImplementingAgency,
  },
  projectDetailView: {
    path: path.projectDetailView,
    element: ProjectDetailView,
  },
  demandDraft: {
    path: path.demandDraft,
    element: DemandDraftView,
  },
  viewDemand: {
    path: path.viewDemand,
    element: DemandNoteView,
  },
  registeredLands: {
    path: path.registeredLands,
    element: RegisteredLands,
  },
  userDetails: {
    path: path.userDetails,
    element: UserDetails,
  },
  landOwnershipDetails: {
    path: path.landOwnershipDetails,
    element: LandOwnershipDetails,
  },
  marketWatch: {
    path: path.marketWatch,
    element: Marketwatch,
  },
  changePassword: {
    path: path.changePassword,
    element: ChangePassword,
  },
  fundTransfer: {
    path: path.fundTransfer,
    element: DefaultFundApplication,
  },
  fundApplicationDetails: {
    path: path.fundApplicationDetails,
    element: FundApplicationDetailView,
  },
  costEstimateDetails: {
    path: path.costEstimateDetails,
    element: CostEstimateDetails,
  },
  progressDetails: {
    path: path.progressDetails,
    element: ProgressDetails,
  },
  updateProgressDetails: {
    path: path.updateProgressDetails,
    element: UpdateProgressDetails,
  },
  advanceWorkProgress: {
    path: path.advanceWorkProgress,
    element: AdvanceWorkProgress,
  },
  progressDetailsReport: {
    path: path.progressDetailsReport,
    element: ProgressDetailsReport,
  },
  divisionProgressDetailsReport: {
    path: path.divisionProgressDetailsReport,
    element: DivisionProgressDetailsReport,
  },
});

type RoutePath = Record<keyof typeof routes, Pick<RouteCOnfig, "path">>;

export const routePath: RoutePath = Object.keys(routes).reduce(
  (value, route) => ({ ...value, [route]: { path: routes[route].path } }),
  {} as RoutePath
);
