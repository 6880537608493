import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import { getProgressReportDetails } from "src/utils/api/dashboard";
import { getStyles } from "src/styles/theme";
import defaultStyles from "./styles";
import { DashBoardTable } from "src/components/common/DashBoardTable";
import { ProgressDetailReport } from "src/components/common/DashBoardTable/progressDetailReport";
import { useLocation } from "react-router-dom";

const ProgressDetailsReport = () => {
  const location = useLocation();
  const { stateId, indicatorId } = location.state || {};

  const [tableRows, setTableRows] = useState([]);

  const [queryParams, setQueryParams] = useState<{
    progressReportFormat: string;
    indicatorIds?: string;
    projectStateIds?: string[];
  }>({
    progressReportFormat: "TABLE",
    indicatorIds: indicatorId, // Add indicatorId
    projectStateIds: stateId ? [stateId] : [], // Add stateId in an array
  });

  useEffect(() => {
    if (stateId && indicatorId) {
      setQueryParams((prev) => ({
        ...prev,
        indicatorIds: indicatorId,
        projectStateIds: [stateId],
      }));
    }
  }, [stateId, indicatorId]);

  const { data: dashboardTable, isLoading: dashboardLoadingTable } = useQuery(
    ["ProgressReportDetailstable", queryParams],
    () => getProgressReportDetails(queryParams),
    {
      onSuccess: (res) => {
        if (res) {
          setTableRows(
            res?.data?.data?.progress.map((items, idx) => ({
              id: idx,
              sno: idx + 1,
              state: items.state,
              indicatorName: items.indicatorName,
              targetInUnits: items.targetInUnits,
              costTarget: items.costTarget,
              progressInUnits: items.progressInUnits,
              costProgress: items.costProgress,
              redirectionKeyValue: items.redirectionKeyValue,
            }))
          );
        }
      },
      refetchOnMount: true,
    }
  );

  const dashboardTableData: DashBoardTable = {
    topHeader: ["", "Target", "Progress"],
    header: [
      "S.No",
      "Division",
      "Activity Name",
      "Physical",
      "Financial",
      "Physical",
      "Financial",
    ],
    data: tableRows,
  };

  const styles = getStyles(defaultStyles);

  return (
    <>
      <Box {...styles("dashboardTableWrapper")}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" {...styles("heading")}>
            Division Wise Progress Details
          </Typography>
        </Box>
        <ProgressDetailReport {...dashboardTableData} />
      </Box>
    </>
  );
};

export default ProgressDetailsReport;
