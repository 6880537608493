import { Box, Grid } from "@mui/material";
import {
  Button,
  DateInput,
  FileUpload,
  Input,
} from "../../../common/FormComponents";
import defaultStyles, { StylesClasses } from "./styles";
import { getStyles } from "src/styles/theme";
import { useForm } from "react-hook-form";

import { AxiosError, AxiosResponse } from "axios";
import { getProgressDetailsHistory } from "src/utils/api/dashboard";

import { useAppSelector } from "src/redux/store";
import { ProgressHistory } from "src/components/common/DashBoardTable/progressHistory";
import { useQuery } from "react-query";

export function AddProgress({ rowData, selfState, refetch }: any) {
  const styles = getStyles<StylesClasses>(defaultStyles);

  const { data: progressDetails } = useQuery(
    ["userDetails", rowData.id],
    () => getProgressDetailsHistory(rowData.id),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      refetchOnMount: "always", // Refetches every time the component mounts
      refetchOnWindowFocus: true,
    }
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      cost: 0,
      quantity: 0,
    },
  });

  const commonTableHeaders = [
    "From Date",
    "To Date",
    "Physical Progress",
    "Financial Progress",
    "View GeoTagged Photos",
  ];

  return (
    <Box {...styles("wrapper")}>
      <Box {...styles("heading")}>Progress History</Box>
      <ProgressHistory
        topHeader={commonTableHeaders}
        data={progressDetails?.data?.data?.progress?.rows || []}
      />
    </Box>
  );
}
